import React, {createContext, useEffect, useReducer} from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import {MatxLoading} from 'app/components';
import useSettings from 'app/hooks/useSettings';
import {MatxLayoutSettings} from 'app/components/MatxLayout/settings'
import {LoadLocalStorage, SaveLocalStorage} from '../utils/Utilities';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { updateSettings } = useSettings();

    const login = async (login, password) => {

        const response = await axios.post('/Accounts/authenticate', { login, password, })
        const { accessToken, accountCompanies } = response.data;
        const user = response.data;
        setSession(accessToken);
        //SaveLocalStorage("accessRoles", null);
        const responseRole = await GetUserPageRoles(accountCompanies[0]?.companyId ?? null);
        user.avatar = await GetAvatar(user);
        SaveLocalStorage("accessRoles", responseRole);
        SaveLocalStorage("userRoles", user?.accountRoles || null);
        SaveLocalStorage("refreshToken", user?.refreshToken);
        dispatch({ type: 'LOGIN', payload: { user } });
    }


    const GetUserPageRoles = async (companyId) => {
        try {
            var response = await axios.get('/ConfigPage/GetUserPageRoles', { params: { companyId: companyId ?? null } });
            return response?.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    const GetAvatar = async (state) => {
        try {
            const response = await axios.get('/Accounts/GetAvatar/' + state?.id);
            var resp = response.data;
            return resp;
        } catch (err) { console.error(err); return null; }
    }



    const register = async (email, username, password) => {
        const response = await axios.post('/Accounts/register', { email, username, password })
        const { accessToken, user } = response.data
        setSession(accessToken)
        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const revokeToken = async (token) => {
        try {
            const response = await axios.post('/Accounts/revoke-token', { token });
            console.log(response?.data);
            return response;
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    const logout = async () => {
        var response = await revokeToken(LoadLocalStorage('refreshToken'));
        setSession(null);
        SaveLocalStorage("accessRoles", null);
        SaveLocalStorage("userRoles", null);
        localStorage.clear();
        updateSettings(MatxLayoutSettings);
        dispatch({ type: 'LOGOUT' })
    }


    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    const response = await axios.get('/Accounts/profile');
                    const user = response.data;
                    user.avatar = await GetAvatar(user);
                    const responseRole = await GetUserPageRoles(LoadLocalStorage('selectedCompany')?.id);
                    SaveLocalStorage("accessRoles", responseRole);
                    SaveLocalStorage("userRoles", user?.accountRoles || null);

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
